export function isAndroidFwf() {
  if (window?.FwfWebInterface) {
    return true;
  }
  return false;
}

export function isIosFwf() {
  if (
    window?.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.FwfWebInterface
  ) {
    return true;
  }
  return false;
}

export function isWebFwf() {
  return !isAndroidFwf() && !isIosFwf();
}
