import { isAndroidFwf, isIosFwf, isWebFwf } from './utils/platform';

let fwf;

async function load () {
  let lib;
  if (isAndroidFwf()) {
    lib = await import(/* webpackChunkName: "fwf/android-fwf" */'./platformFwf/androidFwf');
  } else if (isIosFwf()) {
    lib = await import(/* webpackChunkName: "fwf/ios-fwf" */'./platformFwf/iosFwf');
  } else {
    lib = await import(/* webpackChunkName: "fwf/web-fwf" */'./platformFwf/webFwf');
  }
  return lib.default;
};

export function getFlag(flag, fallback) {
  return fwf.getFlag(flag, fallback);
}

export function setContext(context = {}) {
  return fwf.setContext(context);
}

export function subscribeFeatures(features = []) {
  if (isWebFwf()) {
    return fwf.subscribeFeatures(features);
  }
}

export function unsubscribeFeatures(features = []) {
  if (isWebFwf()) {
    return fwf.unsubscribeFeatures(features);
  }
}

export function subscribeObserver(callback) {
  if (isWebFwf()) {
    fwf.subscribeObserver(callback);
  }
}

export function unsubscribeObserver(callback) {
  if (isWebFwf()) {
    fwf.unsubscribeObserver(callback);
  }
}

export function trackFeature(flag, value) {
  if (isWebFwf()) {
    fwf.trackFeature(flag, value);
  }
}

export async function initialize({ config = {}, context = {} } = {}) {
  if (!fwf) {
    const getLib = await load();
    fwf = getLib(config, context);
  } else {
    setContext(context);
  }
}

export function clear() {
  fwf = null;
}
